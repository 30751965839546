import { AxiosResponse } from "axios";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import PendingConsentType from "../types/PendingConsentType";
import { apiPAB, apiMemberConsents } from "./AxiosService";
import { memberService } from "./MemberService";
import AcceptedAttestationsType from "../types/AcceptedAttestationsType";
import { DateTime } from "luxon";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import SurveyLinkedEntitiesEnum from "../enums/SurveyLinkedEntitiesEnum";

const memberConsentsPABService = apiPAB
  .enhanceEndpoints({ addTagTypes: [ReduxTagEnum.Consents] })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getPendingConsentsByLeadId: builder.query<
        PendingConsentType[],
        { salesforceLeadId: string }
      >({
        query: ({ salesforceLeadId }) => {
          return {
            url: `/consents/pending?lead_id=${salesforceLeadId}`,
            method: "GET"
          };
        }
      }),
      getPendingAttestationsByPatientId: builder.query<
        PendingConsentType[],
        { member_id: string }
      >({
        query: ({ member_id }) => {
          return {
            url: `/attestations/pending?patient_id=${member_id}`,
            method: "GET"
          };
        }
      }),
      getAcceptedAttestations: builder.query<
        AcceptedAttestationsType[],
        { member_id: string }
      >({
        query: ({ member_id }) => {
          return {
            url: `/attestations/accepted?patient_id=${member_id}`,
            method: "GET"
          };
        }
      }),
      getQualifiedAcceptedAttestationsByDateRange: builder.query<
        AcceptedAttestationsType[],
        { accepted_on_after: DateTime; accepted_on_before: DateTime }
      >({
        query: ({ accepted_on_before, accepted_on_after }) => {
          const params = QueryString_stringify({
            is_qualified: true,
            linked_entities: SurveyLinkedEntitiesEnum.PATIENT,
            accepted_on_before: accepted_on_before.toISO(),
            accepted_on_after: accepted_on_after.toISO()
          });
          return {
            url: `/attestations/accepted?${params}`,
            method: "GET"
          };
        }
      })
    })
  });

const memberConsentsService = apiMemberConsents
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      acceptConsents: builder.mutation<
        any,
        Omit<
          {
            member_id: string;
            acceptedConsents: string[];
          },
          "id"
        >
      >({
        query: ({ acceptedConsents, member_id }) => ({
          url: `/accept/patient/${member_id}`,
          data: {
            consent_types_accepted: acceptedConsents
          },
          method: "POST"
        }),

        async onQueryStarted({ member_id }, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(
            memberService.util.invalidateTags(
              member_id
                ? [
                    {
                      type: ReduxTagEnum.Member,
                      id: member_id
                    }
                  ]
                : []
            )
          );
        }
      }),

      downloadConsentPDF: builder.query<
        any,
        Omit<
          {
            accepted_consent_id: string;
          },
          "id"
        >
      >({
        query: ({ accepted_consent_id }) => ({
          url: `/legal-forms/consents/accepted/${accepted_consent_id}/file`,
          method: "GET"
        })
      }),
      downloadAttestationPDF: builder.query<
        any,
        Omit<
          {
            accepted_attestation_id: string;
          },
          "id"
        >
      >({
        query: ({ accepted_attestation_id }) => ({
          url: `/legal-forms/attestations/accepted/${accepted_attestation_id}/file`,
          method: "GET"
        })
      }),

      requestConsents: builder.mutation<
        PendingConsentType,
        { body: { consent: PendingConsentType; enEspañol?: boolean } }
      >({
        query: ({ body }) => ({
          url: `/message/consents${body.enEspañol ? "?lang=es" : ""}`,
          method: "POST",
          data: body.consent,
          validateStatus: (response: AxiosResponse<any, any>) => {
            return response.status === 201 || response.status === 200;
          }
        })
      }),
      requestAttestations: builder.mutation<
        PendingConsentType,
        { body: PendingConsentType }
      >({
        query: ({ body }) => ({
          url: `/message/attestations`,
          method: "POST",
          data: body,
          validateStatus: (response: AxiosResponse<any, any>) => {
            return response.status === 201 || response.status === 200;
          }
        })
      })
    })
  });

export const {
  useGetPendingConsentsByLeadIdQuery,
  useLazyGetPendingConsentsByLeadIdQuery,
  useGetPendingAttestationsByPatientIdQuery,
  useLazyGetPendingAttestationsByPatientIdQuery,
  useGetAcceptedAttestationsQuery,
  useGetQualifiedAcceptedAttestationsByDateRangeQuery
} = memberConsentsPABService;
export const {
  useAcceptConsentsMutation,
  useRequestConsentsMutation,
  useRequestAttestationsMutation,
  useLazyDownloadAttestationPDFQuery,
  useLazyDownloadConsentPDFQuery
} = memberConsentsService;
