import {
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Snackbar,
  Alert,
  SnackbarCloseReason
} from "@mui/material";

import MemberDetailsHeader from "../Header/MemberDetailsHeader";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { useFetchPatientInsurance } from "common/services/MemberInsuranceService";

import {
  checkIdValid,
  formatName,
  getNameOrUsername
} from "common/helpers/helpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetMemberWithUsernameQuery,
  useGetPatientMetadataQuery
} from "common/services/MemberService";
import { DateTime } from "luxon";
import { FormikProps } from "formik";
import {
  blue,
  eligibilityColors,
  error,
  gray,
  success,
  warning
} from "common/styling/colors";
import ErrorComponent from "../../../components/ErrorComponent";
import { Alert_show } from "common/helpers/AlertHelper";
import { RootState, dispatch, useAppDispatch } from "common/redux";
import {
  CalendarMonthOutlined,
  Info,
  InfoOutlined,
  MedicalServicesOutlined,
  PersonOutlineOutlined,
  ScheduleOutlined
} from "@mui/icons-material";
import {
  defaultPrefObject,
  getAmericanTimezone,
  getRecurrenceObject,
  transformTimeOfDayPrefArrayToObject
} from "common/helpers/CalendarHelper";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { LoadingButton } from "@mui/lab";
import {
  resetSelectedTime,
  resetStartIntakeState,
  setActiveStep,
  setMemberInfo,
  setStep3IsLoading,
  setXTraceId
} from "common/redux/StartIntakeSlice";
import { useSelector } from "react-redux";
import { useCreateCalendarEventMutation } from "common/services/CalendarService";
import PatientsMetadataResponse from "common/types/PatientsMetadataResponse";
import AppointmentPreferencesType from "common/types/Calendaring/TimePreferences/AppointmentPreferencesType";
import StartIntakePreferencesFormType from "common/types/Calendaring/TimePreferences/StartIntakePreferencesFormType";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useUpdateMemberCarersMutation } from "common/services/MemberRegistrationService";
import { v4 as uuidv4 } from "uuid";
import FeatureFlags from "common/config/FeatureFlags";
import { yellow } from "@mui/material/colors";
import InsuranceInformationChipStatus from "../../../components/InsuranceInformationChipStatus";
import { SIDEBAR_WIDTH } from "../../../components/Layout";

const MemberDetailsStartIntake = () => {
  const dispatch = useAppDispatch();
  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const traceId = useMemo(() => `intake-${uuidv4()}`, []);

  useEffect(() => {
    dispatch(setXTraceId(traceId));
  }, [traceId]);

  useEffect(() => {
    return () => {
      // reset redux state when navigating away
      dispatch(resetStartIntakeState());
    };
  }, []);

  const {
    data: patientMetadata,
    isSuccess: isSuccessPatientMetadata,
    isFetching: isFetchingPatientMetadata,
    isError: isErrorPatientMetadata,
    refetch: refetchPatientMetadata
  } = useGetPatientMetadataQuery(
    {
      memberId: memberId
    },
    { skip: isValidId === false }
  );

  const { data: memberData, error: memberError } =
    useGetMemberWithUsernameQuery(
      {
        username: memberId,
        linked_entities: [
          MemberLinkedEntitiesEnum.PROVIDER,
          MemberLinkedEntitiesEnum.NURSE
        ]
      },
      { skip: !checkIdValid(memberId) }
    );

  useEffect(() => {
    if (memberData?.patient?.patient_id) {
      dispatch(
        setMemberInfo({
          memberTimezone: memberData?.patient?.timezone,
          memberName: getNameOrUsername(memberData?.patient, false),
          memberAssignedNurse: memberData?.assigned_nurse?.user_id
        })
      );
    }
  }, [memberData?.patient?.patient_id]);

  if (!isValidId) {
    return (
      <Box margin="2.5%">
        <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
      </Box>
    );
  }

  if (memberError) {
    return (
      <Box margin="2.5%">
        <ErrorComponent error={memberError} />
      </Box>
    );
  }

  return (
    <MemberDetailsStartIntakeRender
      appointmentPreferences={{
        data: patientMetadata?.metadata?.preferences?.appointment,
        isFetching: isFetchingPatientMetadata,
        isError: isErrorPatientMetadata,
        isSuccess: isSuccessPatientMetadata
      }}
      memberData={memberData}
      refetchPatientMetadata={refetchPatientMetadata}
    />
  );
};

const MemberDetailsStartIntakeRender = ({
  appointmentPreferences,
  memberData,
  refetchPatientMetadata
}: {
  appointmentPreferences: AppointmentPreferencesType;
  memberData: PatientsMetadataResponse;
  refetchPatientMetadata: () => void;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;

  const { showSidebar } = useSelector((state: RootState) => state.layout);

  const sidebarWidth = useMemo(() => {
    return showSidebar ? SIDEBAR_WIDTH : 12;
  }, [showSidebar]);

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      width={`clamp(calc(70% - ${sidebarWidth}), 100%, calc(100vw - ${sidebarWidth}))`}
      gap="16px"
      overflow="hidden"
    >
      <Box margin="2.5% 2.5% 0 2.5%">
        <MemberDetailsHeader key={`${memberId}-header-encounters`} />
      </Box>
      <Box id="insurance-information-container">
        <Box
          margin="0 2.5% 0 2.5%"
          sx={{
            border: `1px solid ${gray[300]}`,
            backgroundColor: yellow[50],
            padding: "12px",
            borderRadius: "8px",
            fontSize: "0.75rem"
          }}
          display="flex"
          gap={1}
        >
          <InfoOutlined sx={{ width: "16px", height: "16px" }} />
          <span>
            Please keep in mind that after pressing “Start Intake” the system
            can take a few minutes to automatically update the Member’s
            Insurance Information. Having outdated Insurance Information may
            affect how the Provider Matching performs, so if it doesn’t happen
            automatically you can press the “Pull Insurance Information” button.
          </span>
        </Box>
        <Box
          margin="0 2.5% 0 2.5%"
          sx={{
            bgcolor: gray[100],
            borderRadius: "8px",
            padding: "12px",
            border: `1px solid ${gray[300]}`
          }}
        >
          <InsuranceInformationContainer memberId={memberId} />
        </Box>
      </Box>
      <StartIntakeContainer
        appointmentPreferences={appointmentPreferences}
        memberData={memberData}
        refetchPatientMetadata={refetchPatientMetadata}
      />
    </Flexbox>
  );
};

const InsuranceInformationContainer = ({ memberId }: { memberId: string }) => {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const {
    patientinsurances,
    isFetchingPatientInsurance,
    patientInsuranceError,
    lastPulledData,
    isErrorPulling,
    isFetchingPull,
    pullInsurance
  } = useFetchPatientInsurance(memberId);

  useEffect(() => {
    setIsAlertOpen(isErrorPulling);
  }, [isErrorPulling]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") return;
    setIsAlertOpen(false);
  };

  if (isFetchingPatientInsurance) return null;
  if (patientInsuranceError) {
    return (
      <Box margin="2.5%">
        <ErrorComponent error={patientInsuranceError} />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" paddingBottom={1}>
        <Snackbar
          open={isAlertOpen}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Error pulling Insurance information. Try again later.
          </Alert>
        </Snackbar>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          gap={2}
        >
          <Typography color={gray[900]} variant="h5">
            Insurance Information
          </Typography>
          <InsuranceInformationChipStatus
            isFetchingPull={isFetchingPull}
            lastPulledData={lastPulledData}
          />
        </Box>

        <Button
          sx={{
            cursor: "pointer",
            ":hover": { background: gray[100] },
            textDecorationColor: blue[700],
            "&.Mui-disabled": { background: gray[100], color: blue[600] }
          }}
          disabled={isFetchingPull}
          onClick={() => pullInsurance()}
        >
          Pull Insurance Information
        </Button>
      </Box>

      <Box display="flex" flexDirection="row" gap={2}>
        {patientinsurances.map(
          ({
            id,
            athena_irc_name,
            eligibility_status,
            eligibility_status_message
          }) => (
            <TableContainer
              key={id}
              component={Paper}
              elevation={0}
              sx={{ border: 1, borderColor: gray[300] }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight="bold">
                        {athena_irc_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" gap={8}>
                        <Typography>Status</Typography>
                        <Typography
                          color={eligibilityColors[eligibility_status]}
                          fontWeight="bold"
                        >
                          {eligibility_status}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ height: "50px" }}>
                      <Box display="flex" gap={6}>
                        <Typography>Message</Typography>
                        <Typography fontWeight="bold">
                          {eligibility_status_message}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}
      </Box>
    </>
  );
};

const StartIntakeContainer = ({
  appointmentPreferences,
  memberData,
  refetchPatientMetadata
}: {
  appointmentPreferences: AppointmentPreferencesType;
  memberData: PatientsMetadataResponse;
  refetchPatientMetadata: () => void;
}) => {
  const navigate = useNavigate();

  const preferencesFormRef =
    useRef<FormikProps<StartIntakePreferencesFormType>>();

  const preferencesFormDefaults = {
    frequency: appointmentPreferences?.data?.frequency ?? "",
    MONDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.MONDAY
      ) ?? defaultPrefObject,
    TUESDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.TUESDAY
      ) ?? defaultPrefObject,
    WEDNESDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.WEDNESDAY
      ) ?? defaultPrefObject,
    THURSDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.THURSDAY
      ) ?? defaultPrefObject,
    FRIDAY:
      transformTimeOfDayPrefArrayToObject(
        appointmentPreferences?.data?.times_of_day?.FRIDAY
      ) ?? defaultPrefObject
  };

  const {
    activeStep,
    step2SelectionsAreValid,
    step2IsLoading,
    step3IsLoading,
    selectedStartDate,
    selectedEndDate,
    staffId,
    staffName,
    memberName,
    memberAssignedNurse,
    recurrence,
    memberTimezone,
    communication_type,
    appointmentType,
    xTraceId
  } = useSelector((state: RootState) => state.startIntake);

  const onBack = useCallback(() => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      dispatch(setActiveStep(activeStep - 1));
    }
  }, [activeStep]);

  // onNext is use to handle moving to the next step, invoke this in child components
  const onNext = useCallback(() => {
    if (activeStep === 2) {
      // navigate to the member chart
      navigate(
        `/members/memberId/${memberData?.patient?.patient_id}/appointments`
      );
    } else {
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [activeStep]);

  const [
    createCalendarEventMutation,
    {
      data: createCalendarEventData,
      error: createCalendarEventError,
      reset: resetCreateCalendarEvent
    }
  ] = useCreateCalendarEventMutation();

  const [assignNursePatientMutation] = useUpdateMemberCarersMutation();

  useEffect(() => {
    if (createCalendarEventData) {
      const startDateTime =
        DateTime.fromISO(selectedStartDate).setZone(memberTimezone);
      const endDateTime =
        DateTime.fromISO(selectedEndDate).setZone(memberTimezone);
      const dateAndTime = `${startDateTime.weekdayLong} | ${startDateTime.toFormat("hh:mm")}-${endDateTime.toFormat("hh:mm a")} ${getAmericanTimezone(memberTimezone)}`;

      if (memberAssignedNurse !== staffId) {
        const assignNursePatientRequest = {
          patient_id: memberData?.patient?.patient_id,
          carers: {
            nurse_id: staffId
          }
        };
        assignNursePatientMutation(assignNursePatientRequest);
      }

      dispatch(setStep3IsLoading(false));
      onNext();
      const formattedRecurrence = formatName(recurrence);
      Alert_show({
        dispatch,
        id: "createEventSuccess",
        title: "Appointment Scheduled",
        content: (
          <Box
            style={{
              display: "flex",
              gap: "16px",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "140px",
                  gap: "8px"
                }}
              >
                <PersonOutlineOutlined />
                <Typography variant="body1" color="#475467">
                  Member
                </Typography>
              </Box>
              <Typography variant="body1" fontWeight={600}>
                {memberName}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "140px",
                  gap: "8px"
                }}
              >
                <CalendarMonthOutlined />
                <Typography variant="body1" color="#475467">
                  Date and Time
                </Typography>
              </Box>
              <Typography
                variant="body1"
                fontWeight={600}
                data-testid="Date and Time"
              >
                {dateAndTime}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "140px",
                  gap: "8px"
                }}
              >
                <ScheduleOutlined />
                <Typography variant="body1" color="#475467">
                  Cadence
                </Typography>
              </Box>
              <Typography
                variant="body1"
                fontWeight={600}
                data-testid="Cadence"
              >
                {formattedRecurrence}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-start"
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "140px",
                  gap: "8px"
                }}
              >
                <MedicalServicesOutlined />
                <Typography variant="body1" color="#475467">
                  Nurse
                </Typography>
              </Box>
              <Typography variant="body1" fontWeight={600}>
                {staffName}
              </Typography>
            </Box>
          </Box>
        ),
        type: "default",
        size: {
          width: "440px",
          height: "440px"
        }
      });
    }

    dispatch(setStep3IsLoading(false));
    onNext();
    resetCreateCalendarEvent();
    dispatch(resetStartIntakeState());
  }, [createCalendarEventData]);

  useEffect(() => {
    if (createCalendarEventError) {
      dispatch(setStep3IsLoading(false));
      Alert_show({
        dispatch,
        id: "createCalendarEventError",
        title: "Error",
        content: <ErrorComponent error={createCalendarEventError} />,
        type: "warning",
        size: "small"
      });
      dispatch(resetSelectedTime());
    }
  }, [createCalendarEventError]);

  // onClick is used to handle API calls
  const step2OnClick = () => {
    if (preferencesFormRef.current) {
      preferencesFormRef.current.handleSubmit();
    }
  };

  const step3OnClick = () => {
    const attendees = [
      {
        attendee_type: "PATIENT",
        patient_id: memberData?.patient?.patient_id,
        email: memberData?.patient?.email
      }
    ];

    const recurrenceObject = getRecurrenceObject(
      recurrence,
      selectedStartDate,
      selectedEndDate,
      memberTimezone
    );

    dispatch(setStep3IsLoading(true));
    createCalendarEventMutation({
      body: {
        attendees,
        start_date: selectedStartDate,
        end_date: selectedEndDate,
        communication_type,
        appointment_type: appointmentType,
        created_by: staffId,
        ...(recurrenceObject && { recurrence: recurrenceObject })
      },
      patientId: memberData?.patient?.patient_id,
      staffId,
      xTraceId
    });
  };

  return (
    <Flexbox
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
      width="100%"
      sx={{ overflowY: "scroll" }}
    >
      <Box margin="0 2.5%" sx={{ overflowY: "scroll" }}>
        <Stepper activeStep={activeStep} sx={{ marginBottom: "32px" }}>
          <Step key="startIntake" sx={{ paddingLeft: 0 }}>
            <StepLabel>Start Intake</StepLabel>
          </Step>
          <Step key="selectTimePreferences">
            <StepLabel>Select Time Preferences</StepLabel>
          </Step>
          <Step key="confirmAppointment" sx={{ paddingRight: 0 }}>
            <StepLabel>Confirm Appointment</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 1 && (
          <Step2
            ref={preferencesFormRef}
            preferencesFormDefaults={preferencesFormDefaults}
            appointmentPreferences={appointmentPreferences}
            onNext={onNext}
            key={`selectTimePreferences${memberData?.patient?.patient_id}`}
            refetchPatientMetadata={refetchPatientMetadata}
          />
        )}
        {activeStep === 2 && (
          <div>
            <Step3
              onBack={onBack}
              onNext={onNext}
              defaultCadence={recurrence ?? preferencesFormDefaults?.frequency}
              key={`confirmAppointment${memberData?.patient?.patient_id}`}
            />
          </div>
        )}
      </Box>

      <Flexbox
        justifyContent="flex-end"
        gap="16px"
        padding="16px 2.5%"
        bgcolor="white"
        border={`1px solid ${gray[300]}`}
      >
        <Button sx={{ width: "185px" }} variant="outlined" onClick={onBack}>
          Back
        </Button>
        {activeStep === 1 && (
          <LoadingButton
            loading={step2IsLoading}
            sx={{ width: "185px" }}
            variant="contained"
            onClick={step2OnClick}
            disabled={!step2SelectionsAreValid || step2IsLoading}
          >
            Next
          </LoadingButton>
        )}
        {activeStep === 2 && (
          <LoadingButton
            loading={step3IsLoading}
            sx={{ width: "185px" }}
            variant="contained"
            onClick={step3OnClick}
            disabled={
              !selectedStartDate ||
              !selectedEndDate ||
              !recurrence ||
              !staffId ||
              step3IsLoading
            }
            data-testid="Confirm"
          >
            Confirm
          </LoadingButton>
        )}
      </Flexbox>
    </Flexbox>
  );
};

export default MemberDetailsStartIntake;
