import { Card, CardContent, Popover, Typography, styled } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../components/Table/Table";
import {
  useLazyDownloadAttestationPDFQuery,
  useLazyDownloadConsentPDFQuery,
  useRequestAttestationsMutation,
  useRequestConsentsMutation,
  useGetPendingAttestationsByPatientIdQuery,
  useGetPendingConsentsByLeadIdQuery
} from "common/services/MemberConsentsService";
import { DateTime } from "luxon";
import { RootState, useAppDispatch } from "common/redux";
import MemberType from "common/types/MemberType";
import MemberAcceptedLegalFormsType from "common/types/MemberAcceptedLegalFormsType";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { DownChevronIcon, LinkIcon } from "../../../assets/images/icons";
import { CustomTooltip, SuccessText } from "../../../styling/StyleComponents";
import { LoadingButton } from "@mui/lab";
import ErrorComponent from "../../../components/ErrorComponent";
import { Alert_show } from "common/helpers/AlertHelper";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { copyToClipboard } from "../../../styling/CopyPatientLinkToClipboard";
import APIConstants from "common/config/APIConstants";
import { isFalsy, PHONE_VALIDATION_REGEXP } from "common/helpers/helpers";
import { canDownloadAgreementsPdf } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";

const POST_SEND_POLLING_INTERVAL = 2500;

const PopoverMenuItem = styled(Typography)`
  display: flex;
  gap: 10px;
  align-items: center;
  :hover {
    background: ${(props) => props.theme.palette.grey[100]};
    cursor: pointer;
  }
`;

const StyledCardRow = styled(CardContent)`
  flex-direction: row;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 24px;
`;

const StyledLinkIcon = styled(LinkIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

enum KindEnum {
  ATTESTATION,
  CONSENTS
}

interface IProps {
  patient: MemberType;
  onlyConsents?: boolean; // If true, will only show consents, ignoring attestations
  hideCompletedAgreements?: boolean;
  hideHeader?: boolean;
  width?: number;
  children?: any;
}

const MemberAgreements = ({
  patient,
  onlyConsents = false,
  hideCompletedAgreements = false,
  hideHeader = false,
  width,
  children = []
}: IProps) => {
  const dispatch = useAppDispatch();

  const { currentRole } = useSelector((state: RootState) => state.auth);

  const [refresh, setRefresh] = useState<number>(0);
  const refreshTimerRef = useRef<NodeJS.Timeout>();

  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
  const [kind, setKind] = useState<KindEnum>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const allowSendSms = PHONE_VALIDATION_REGEXP.test(patient?.patient?.mobile);
  const allowSendEmail = patient?.patient?.contact_info?.email?.value;

  const completedTableColumns = useMemo(() => {
    const columns = [
      {
        id: "formName",
        name: "agreementsFormName",
        accessor: "name",
        header: "Form Name"
      },
      {
        id: "formVersion",
        name: "default",
        accessor: "version",
        header: "Version"
      },

      {
        name: "agreementsSigned"
      }
    ];
    if (canDownloadAgreementsPdf(currentRole))
      columns.push({
        name: "agreementsCompletedActions"
      });
    return columns;
  }, [currentRole]);

  useEffect(() => {
    if (copiedToClipboard) {
      refreshTimerRef.current = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
    return () => {
      if (refreshTimerRef.current) clearTimeout(refreshTimerRef.current);
    };
  }, [copiedToClipboard]);

  useEffect(() => {
    return () => {
      if (refreshTimerRef.current && refresh > 0)
        clearTimeout(refreshTimerRef.current);
    };
  }, []);

  const [
    requestAttestationsMutation,
    {
      isLoading: requestAttestationsLoading,
      isSuccess: requestAttestationsSuccess,
      error: requestAttestationsError
    }
  ] = useRequestAttestationsMutation();

  const [
    requestConsentsMutation,
    {
      error: requestConsentsError,
      isSuccess: requestConsentsSuccess,
      isLoading: requestConsentsLoading
    }
  ] = useRequestConsentsMutation();

  const [downloadConsentPDFQuery] = useLazyDownloadConsentPDFQuery();
  const [downloadAttestationPDFQuery] = useLazyDownloadAttestationPDFQuery();

  const triggerRefresh = () => {
    setRefresh(POST_SEND_POLLING_INTERVAL);
    if (refreshTimerRef.current) clearTimeout(refreshTimerRef.current);
    refreshTimerRef.current = setTimeout(() => {
      setRefresh(0);
      clearTimeout(refreshTimerRef.current);
    }, POST_SEND_POLLING_INTERVAL * 2);
  };

  const handleDownloadPDF = (item: MemberAcceptedLegalFormsType) => {
    const promiseSuccess = ({ data }) => {
      if (data?.encoded_file) {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data.encoded_file;
        // @ts-ignore
        downloadLink.download = item.name + ".pdf";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        Alert_show({
          dispatch,
          type: "error",
          size: "small",
          title: "Download Error",
          content: "An error has occured while downloading the pdf"
        });
      }
    };

    if (item.accepted_consent_id) {
      downloadConsentPDFQuery({
        accepted_consent_id: item.accepted_consent_id
      }).then(({ data }) => promiseSuccess({ data }));
    }

    if (item.accepted_attestation_id) {
      downloadAttestationPDFQuery({
        accepted_attestation_id: item.accepted_attestation_id
      }).then(({ data }) => promiseSuccess({ data }));
    }
  };

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLElement>,
    kind: KindEnum
  ) => {
    setKind(kind);
    setAnchorElement(event.currentTarget);
  };

  const popoverEmailHandler = async () => {
    if (kind === KindEnum.CONSENTS) {
      requestConsentsMutation({
        body: {
          consent: {
            lead_id: patient.patient.patient_id,
            email: patient.patient.email
          }
        }
      });
    } else if (kind === KindEnum.ATTESTATION) {
      await requestAttestationsMutation({
        body: {
          patient_id: patient?.patient?.patient_id,
          email: patient.patient.email,
          attestation_type: "FINANCIAL_ASSISTANCE"
        }
      });
    }
    triggerRefresh();
    setAnchorElement(null);
  };
  const popoverSMSHandler = async () => {
    if (kind === KindEnum.CONSENTS) {
      requestConsentsMutation({
        body: {
          consent: {
            lead_id: patient.patient.patient_id,
            mobile: patient.patient.mobile
          }
        }
      });
    } else if (kind === KindEnum.ATTESTATION) {
      await requestAttestationsMutation({
        body: {
          patient_id: patient?.patient?.patient_id,
          mobile: patient?.patient?.mobile,
          attestation_type: "FINANCIAL_ASSISTANCE"
        }
      });
    }
    triggerRefresh();
    setAnchorElement(null);
  };

  const {
    data: pendingAttestations,
    isFetching: pendingAttestationsIsFetching
  } = useGetPendingAttestationsByPatientIdQuery(
    { member_id: patient?.patient?.patient_id },
    { skip: !patient?.patient?.patient_id, pollingInterval: refresh }
  );

  const { data: pendingConsents, isFetching: pendingConsentsIsFetching } =
    useGetPendingConsentsByLeadIdQuery(
      { salesforceLeadId: patient?.patient?.patient_id },
      { skip: !patient?.patient?.patient_id, pollingInterval: refresh }
    );

  const acceptedLegalForms = useMemo(() => {
    return patient?.accepted_legal_forms?.map((item) => {
      return {
        ...item,
        name: item.consent_name ?? item.attestation_name,
        type: item.consent_type ?? item.attestation_type,
        version: item.consent_version ?? item.attestation_form_version
      };
    });
  }, [patient]);

  const missingLegalForms = useMemo(() => {
    if (!pendingAttestations || !pendingConsents) return [];
    // check for patient?.missing_legal_forms_full then patient?.missing_legal_forms
    const data = isFalsy(patient?.missing_legal_forms_full)
      ? patient?.missing_legal_forms
      : patient?.missing_legal_forms_full;

    const sortingFn = (a, b) => {
      return (
        DateTime.fromISO(a.created).millisecond -
        DateTime.fromISO(b.created).millisecond
      );
    };
    const sortedPendingAttestations = [...pendingAttestations].sort(sortingFn);
    const sortedPendingConsents = [...pendingConsents].sort(sortingFn);

    return data?.map((item) => {
      const is_consent = item?.consent_type?.length > 0;
      let code;

      let requestedDate = undefined,
        viewedDate = undefined;
      if (is_consent && sortedPendingConsents?.length > 0) {
        requestedDate =
          sortedPendingConsents[sortedPendingConsents?.length - 1]?.created;
        viewedDate =
          sortedPendingConsents[sortedPendingConsents?.length - 1]?.viewed;
      } else if (!is_consent && sortedPendingAttestations?.length > 0) {
        requestedDate =
          sortedPendingAttestations[sortedPendingAttestations?.length - 1]
            ?.created;
        viewedDate =
          sortedPendingAttestations[sortedPendingAttestations?.length - 1]
            ?.viewed;
      }

      if (
        sortedPendingAttestations?.findIndex(
          (attestation) =>
            attestation.attestation_type === item.attestation_type
        ) !== -1
      ) {
        const attestation = sortedPendingAttestations?.find(
          (attestation) =>
            attestation.attestation_type === item.attestation_type
        );

        code = attestation?.code;
      }

      return {
        ...item,
        requested_date: requestedDate,
        viewed: viewedDate,
        code
      };
    });
  }, [patient, pendingAttestations, pendingConsents]);

  const isFetching = pendingConsentsIsFetching || pendingAttestationsIsFetching;

  return (
    <div style={{ width: width ?? "auto", overflow: "scroll" }}>
      {isFetching && <LoadingFallback count={5} />}
      {!isFetching && (
        <>
          <Flexbox justifyContent="space-between" alignItems="center">
            <Typography variant="h3" color="text.secondary" mt="20px" mb="20px">
              {isFalsy(hideHeader) ? "Missing Agreements" : ""}
            </Typography>

            {!isFalsy(missingLegalForms) && (
              <Flexbox gap={"40px"}>
                {!onlyConsents && (
                  <CustomTooltip>
                    <Flexbox flexDirection={"column"}>
                      <Flexbox gap={"4px"} alignItems={"center"}>
                        {pendingAttestations &&
                          pendingAttestations.length > 0 && (
                            <CustomTooltip
                              placement="top"
                              title={`${copiedToClipboard ? "Copied!" : "Copy link sent to member"}`}
                            >
                              <StyledLinkIcon
                                copiedToClipboard={copiedToClipboard}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  const latestAttestation =
                                    pendingAttestations[
                                      pendingAttestations.length - 1
                                    ];
                                  const code = latestAttestation?.code;
                                  const url = `${APIConstants.WEBSITE}/financial-assistance?code=${code}`;

                                  const copyToClipboardResult =
                                    await copyToClipboard(url);
                                  if (copyToClipboardResult) {
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </CustomTooltip>
                          )}
                        <LoadingButton
                          variant="contained"
                          endIcon={<DownChevronIcon />}
                          onClick={(event) =>
                            handlePopoverClick(event, KindEnum.ATTESTATION)
                          }
                          loading={requestAttestationsLoading}
                        >
                          Request Financial Assistance
                        </LoadingButton>
                      </Flexbox>

                      {requestAttestationsSuccess && (
                        <SuccessText>
                          Requested financial assistance successfully.
                        </SuccessText>
                      )}
                      <ErrorComponent error={requestAttestationsError} />
                    </Flexbox>
                  </CustomTooltip>
                )}

                <CustomTooltip>
                  <Flexbox flexDirection={"column"}>
                    <Flexbox gap={"4px"} alignItems={"center"}>
                      {pendingConsents && pendingConsents.length > 0 && (
                        <CustomTooltip
                          placement="top"
                          title={`${copiedToClipboard ? "Copied!" : "Copy link sent to member"}`}
                        >
                          <StyledLinkIcon
                            copiedToClipboard={copiedToClipboard}
                            onClick={async (e) => {
                              e.preventDefault();
                              const latestConsent =
                                pendingConsents[pendingConsents.length - 1];
                              const code = latestConsent?.code;
                              const url = `${APIConstants.WEBSITE}/accept-consents?code=${code}`;

                              const copyToClipboardResult =
                                await copyToClipboard(url);
                              if (copyToClipboardResult) {
                                setCopiedToClipboard(true);
                              }
                            }}
                          />
                        </CustomTooltip>
                      )}
                      <LoadingButton
                        variant="contained"
                        endIcon={<DownChevronIcon />}
                        onClick={(event) =>
                          handlePopoverClick(event, KindEnum.CONSENTS)
                        }
                        loading={requestConsentsLoading}
                      >
                        Request Consents
                      </LoadingButton>
                    </Flexbox>
                    {requestConsentsSuccess && (
                      <SuccessText>
                        Requested consents successfully.
                      </SuccessText>
                    )}
                    <ErrorComponent error={requestConsentsError} />
                  </Flexbox>
                </CustomTooltip>
              </Flexbox>
            )}
          </Flexbox>
          {isFalsy(missingLegalForms) ? (
            <Card>
              <StyledCardRow>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign={"center"}
                >
                  Member has all required agreements at this time
                </Typography>
              </StyledCardRow>
            </Card>
          ) : (
            <Table
              tableColumns={[
                {
                  id: "formName",
                  name: "agreementsFormName",
                  accessor: "name",
                  header: "Form Name"
                },
                {
                  id: "formVersion",
                  name: "default",
                  accessor: "tag",
                  header: "Version"
                },
                {
                  name: "agreementsInformation"
                },
                {
                  name: "agreementsViewed"
                }
              ]}
              initialSortingState={[
                {
                  id: "formName",
                  desc: false
                }
              ]}
              tableProps={{
                copiedToClipboard,
                setCopiedToClipboard
              }}
              data={missingLegalForms}
            />
          )}
          {isFalsy(hideCompletedAgreements) && (
            <>
              <Typography
                variant="h3"
                color="text.secondary"
                mt="40px"
                mb="20px"
              >
                {isFalsy(hideHeader) ? "Completed Agreements" : ""}
              </Typography>
              {patient?.accepted_legal_forms && (
                <Table
                  noDataText="No completed agreements found."
                  initialSortingState={[
                    {
                      id: "formName",
                      desc: false
                    }
                  ]}
                  tableColumns={completedTableColumns}
                  data={acceptedLegalForms}
                  tableProps={{ dispatch, handleDownloadPDF }}
                  estimateRowSize={(index) => {
                    const item = patient?.accepted_legal_forms[index];
                    if (item.expiration) return 70;
                    return 50;
                  }}
                />
              )}
            </>
          )}
        </>
      )}

      <Popover
        id={anchorElement !== null ? "simple-popover" : undefined}
        open={anchorElement !== null}
        anchorEl={anchorElement}
        style={{ zIndex: 10001 }}
        onClose={() => {
          setAnchorElement(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <>
          {allowSendSms && (
            <PopoverMenuItem sx={{ p: 2 }} onClick={popoverSMSHandler}>
              By SMS
            </PopoverMenuItem>
          )}
          {allowSendEmail && (
            <PopoverMenuItem sx={{ p: 2 }} onClick={popoverEmailHandler}>
              By email
            </PopoverMenuItem>
          )}
        </>
      </Popover>
      <br />
      {!isFalsy(missingLegalForms) && <>{children}</>}
    </div>
  );
};

export default MemberAgreements;
