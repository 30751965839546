import { useEffect, useState } from "react";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import PatientInsuranceType from "../types/PatientInsuranceType";
import { apiPAB, apiMemberRegistration } from "./AxiosService";
import { sortBy } from "lodash";
import { DateTime } from "luxon";


export const patientInsuranceService = apiPAB
    .enhanceEndpoints({
        addTagTypes: [ReduxTagEnum.PatientInsurance]
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getPatientInsuranceByPatientId: builder.query<
            PatientInsuranceType[],
            { patientId: string }
            >({
                query: ({ patientId }) => ({
                    url: `/patientinsurance/patient/${patientId}`,
                    method: "GET",
                }),
            }),
        }),
    });

export const patientRegistrationInsuranceService = apiMemberRegistration
    .injectEndpoints({
        endpoints: (builder) => ({
            getPatientInsurancePullByPatientId: builder.query<
            PatientInsuranceType[],
            { patientId: string }
            >({
                query: ({ patientId }) => ({
                    url: `/patients/${patientId}/insurance/pull`,
                    method: "POST",
                }),
            }),
        }),
    });

const { useGetPatientInsuranceByPatientIdQuery } = patientInsuranceService;
const { useGetPatientInsurancePullByPatientIdQuery } = patientRegistrationInsuranceService;

const getLatestPulledDate = (records: [string, any][][]): string | null => {
    if (records.length === 0) return null;

    const mappedRecords = records.map(record => Object.fromEntries(record));

    if (mappedRecords.length === 0) return null;

    return mappedRecords.reduce((latest, current) => 
        DateTime.fromISO(current.last_pulled_date || 0) > DateTime.fromISO(latest.last_pulled_date || 0) 
            ? current 
            : latest
    , { last_pulled_date: DateTime.now().toISO() }).last_pulled_date || null;
}

export const useFetchPatientInsurance = (patientId: string) => {
    const {
      data: patientinsurances,
      isFetching: isFetchingPatientInsurance,
      error: patientInsuranceError,
    } = useGetPatientInsuranceByPatientIdQuery({ patientId });

    const {
      isFetching: isFetchingPull,
      error: errorPulling,
      isError: isErrorPulling,
      refetch: refetchInsurancePull,
    } = useGetPatientInsurancePullByPatientIdQuery({ patientId });

    const safePatientInsurances = patientinsurances ?? []

    const patientInsuranceMap = sortBy(safePatientInsurances, (i) => i.sequence_number).map(Object.entries)

    const lastPulledData = safePatientInsurances.length > 0
        ? DateTime.fromISO(getLatestPulledDate(patientInsuranceMap))
        : null;

    const pullInsurance = async () => {
        await refetchInsurancePull();
    };

    return {
        patientinsurances: safePatientInsurances,
        patientInsuranceMap,
        isFetchingPatientInsurance,
        patientInsuranceError,
        isFetchingPull,
        errorPulling,
        lastPulledData,
        isErrorPulling,
        pullInsurance,
    };
}
