import { forwardRef, lazy, memo, Ref, Suspense, useRef, useState } from "react";
import styled from "@emotion/styled";

import { DateTime } from "luxon";
import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

import { getNameOrUsername, isTruthy } from "common/helpers/helpers";
import MemberType from "common/types/MemberType";

import {
  TableComponentContainer,
  ComponentHeader
} from "../../../styling/StyleComponents";
import ReportReadings from "./ReportReadings";
import PatientChartShare from "./PatientChartShare";
import ReportEncounters from "./ReportEncounters";
import ReportAttrition from "./ReportAttrition";
import ReportSMSReplies from "./ReportSMSReplies";
import ReportType from "../../../types/ReportType";

import PDFHelper from "../../../helpers/PDFHelper";
import ReportRefInterface from "./ReportRefInterface";
import ReportEnum from "../../../enums/ReportEnum";
import { CopilotIQFullLogo } from "../../../assets/images";
import DropdownType from "../../../types/DropdownType";

import ErrorComponent from "../../../components/ErrorComponent";
import RolesEnum from "common/enums/RolesEnum";
import { blue } from "common/styling/colors";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { CsvHelper_download } from "../../../helpers/CsvHelper";
import ReportTimeByINs from "./ReportTimeByINs";
import ReportTimeByTNs from "./ReportTimeByTNs";
import ReportFinancialAssistance from "./ReportFinancialAssistance";
import ErrorType from "common/types/ErrorType";
const DownloadChartSplitButton = lazy(
  () => import("../../../components/Button/DownloadChartSplitButton")
);

const SubmitToAthenaButton = lazy(
  () => import("../../../components/Button/SubmitToAthenaButton")
);

interface IProps {
  patient: MemberType;
  startDate: DateTime;
  endDate: DateTime;
  submitterRoles?: RolesEnum[];
  dropdownSelection: DropdownType[];
  checkboxChecked?: boolean;
  reportType?: ReportType;
  paramsPatientId?: string;
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const TableHeading = styled(ComponentHeader)`
  margin: 24px 0px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const PatientChartShareRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const CopilotIQLogoContainer = styled.div`
  text-align: center;
`;

const RenderReport = forwardRef(
  (
    {
      patient,
      startDate,
      endDate,
      dropdownSelection,
      reportType,
      checkboxChecked,
      submitterRoles
    }: IProps,
    ref: Ref<ReportRefInterface>
  ) => {
    switch (reportType?.id) {
      case ReportEnum.ENCOUNTERS:
        return (
          <ReportEncounters
            ref={ref}
            patient={patient}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case ReportEnum.READINGS:
        return (
          <ReportReadings
            ref={ref}
            patient={patient}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case ReportEnum.FINANCIAL_ASSISTANCE_QUALIFICATIONS:
        return (
          <ReportFinancialAssistance
            ref={ref}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case ReportEnum.TIME_ENTERED_BY_INS:
        return (
          <ReportTimeByINs
            ref={ref}
            startDate={startDate}
            endDate={endDate}
            dropdownSelection={dropdownSelection}
            checkboxChecked={checkboxChecked}
          />
        );
      case ReportEnum.TIME_ENTERED_BY_TNS:
        return (
          <ReportTimeByTNs
            ref={ref}
            startDate={startDate}
            endDate={endDate}
            dropdownSelection={dropdownSelection}
            checkboxChecked={checkboxChecked}
          />
        );
      case ReportEnum.ATTRITION:
        return (
          <ReportAttrition
            id={reportType.id}
            ref={ref}
            startDate={startDate}
            endDate={endDate}
            submitterRoles={submitterRoles}
            csvFilename="attrition"
          />
        );
      case ReportEnum.REQUESTS_TO_CANCEL:
        return (
          <ReportAttrition
            id={reportType.id}
            ref={ref}
            startDate={startDate}
            endDate={endDate}
            submitterRoles={submitterRoles}
            csvFilename="requests_to_cancel"
          />
        );
      case ReportEnum.SMS_REPLIES:
        return (
          <ReportSMSReplies
            id={reportType.id}
            ref={ref}
            startDate={startDate}
            endDate={endDate}
            csvFilename="sms_replies"
          />
        );
      case ReportEnum.MEMBER_CHART_SHARE:
        return (
          <PatientChartShare
            ref={ref}
            patient={patient}
            startDate={startDate}
            endDate={endDate}
          />
        );
      default:
        return null;
    }
  }
);
const MemoizedRenderReport = memo(RenderReport);

const ReportSelector = ({
  patient,
  startDate,
  endDate,
  dropdownSelection,
  reportType,
  checkboxChecked,
  paramsPatientId
}: IProps) => {
  const ref = useRef<ReportRefInterface>();

  const isUserSet = !(reportType?.userRequired && patient === undefined);
  const isDropdownSet = !(
    reportType?.dropdown !== undefined && dropdownSelection.length === 0
  );

  const showsError = isUserSet === false || isDropdownSet === false;

  const [patientChartShareError, setPatientChartShareError] =
    useState<ErrorType>();
  const [displayAllReadings, setDisplayAllReadings] = useState<boolean>(false);

  return (
    <TableContainer>
      <TableComponentContainer
        width={showsError ? (reportType?.containerMaxWidth ?? "600px") : "100%"}
        margin="0px"
      >
        <CopilotIQLogoContainer>
          <CopilotIQFullLogo id="copilot-logo" />
        </CopilotIQLogoContainer>

        {patient &&
          // hide this header if we are on the member chart share report since this will be shown in the header
          !(
            paramsPatientId && reportType?.id === ReportEnum.MEMBER_CHART_SHARE
          ) && (
            <TableHeading>
              {reportType?.title}: {getNameOrUsername(patient.patient)}
              {/* an improvement we can make here is to restrict routes by role, this is low priority */}
            </TableHeading>
          )}
        {showsError && (
          <>
            <br />
            <Typography variant="body1">
              {reportType.noInputDataText}
            </Typography>
          </>
        )}
        {reportType?.id === ReportEnum.MEMBER_CHART_SHARE ? (
          <>
            <Typography variant="body1">
              {startDate.toFormat("MM/dd/yyyy")}&nbsp;-&nbsp;
              {endDate.toFormat("MM/dd/yyyy")}
            </Typography>

            {isTruthy(patient) && (
              <FormControlLabel
                control={
                  <Checkbox
                    id="allReadings"
                    checked={displayAllReadings}
                    onChange={() => {
                      setDisplayAllReadings((prevState) => !prevState);
                    }}
                  />
                }
                label="Display all readings for date range"
              />
            )}
            <br />
            {showsError === false && (
              <>
                <PatientChartShareRow>
                  <Suspense fallback={<LoadingFallback />}>
                    <DownloadChartSplitButton
                      ref={ref}
                      patient={patient?.patient}
                      displayAllReadings={displayAllReadings}
                    />
                    <SubmitToAthenaButton
                      ref={ref}
                      patient={patient}
                      startDate={startDate}
                      endDate={endDate}
                      setPatientChartShareError={setPatientChartShareError}
                    />
                  </Suspense>
                </PatientChartShareRow>
                {patientChartShareError && (
                  <>
                    <ErrorComponent
                      style={{ marginTop: "8px" }}
                      error={patientChartShareError}
                    />
                    <ErrorComponent
                      style={{ marginTop: "8px" }}
                      error={"Please retry."}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Row>
            {showsError === false && (
              <>
                <Button onClick={() => CsvHelper_download(ref)}>
                  Export as CSV
                </Button>

                <Button
                  onClick={() => {
                    void (async () => {
                      await PDFHelper.generateReport({
                        ref,
                        patient,
                        dropdownSelection,
                        dateFrom: startDate,
                        dateTo: endDate,
                        report: reportType,
                        color: blue[700]
                      });
                    })();
                  }}
                >
                  Export as PDF
                </Button>
              </>
            )}
          </Row>
        )}

        <MemoizedRenderReport
          ref={ref}
          patient={patient}
          startDate={startDate}
          endDate={endDate}
          dropdownSelection={dropdownSelection}
          reportType={reportType}
          checkboxChecked={checkboxChecked}
          submitterRoles={reportType?.submitterRoles}
        />
      </TableComponentContainer>
    </TableContainer>
  );
};

export default ReportSelector;
